<template>
    <div>
        <Pane :showRemarks="false" :bottom="0">
            <a-tabs v-model="active" :tabBarStyle="{
                margin: '0',
            }">
                <a-tab-pane key="0" tab="危险有害因素辨识"> </a-tab-pane>
                <a-tab-pane key="1" tab="风险分级管控"> </a-tab-pane>
            </a-tabs>
        </Pane>
        <a-card class="container">
            <Company :list="List" v-show="active === '0'"/>
            <Project :list="List" v-show="active === '1'"/>
        
            <div class="center">
                <a-space>
                    <a-button @click="$close($route.path)">关闭</a-button>
                    <a-button type="primary" :loading="loading" @click="process">保存并完成</a-button>
                    <a-button type="primary" :loading="loading" @click="handleSubmit">保存</a-button>
                </a-space>
            </div>
        </a-card>
    </div>
</template>
  
<script>
import request from "@/api/request";
import Company from "./components/Company.vue";
import Project from "./components/Project.vue";
import organization from "@/mixins/organization";
function assessed(data) {
    return request({
        url: "/office-service/quality/risk/object/task/assessed",
        method: "post",
        data
    });
}
function save(data) {
    return request({
        url: "/office-service/quality/risk/object/task/save",
        method: "post",
        data
    });
}
function getDetail(id) {
  return request({
    url: "/office-service/quality/risk/object/task/queryAssessmentList/" + id,
  });
}
export default {
    name: "technicalQualityScoreAdd",
    mixins: [organization],
    components: {
        Company,
        Project
    },

    data() {
        return {
            active: "0",
            List: [],
            loading: false,
        };
    },
    mounted() {
        const { query } = this.$route;
        const { id } = query || {};
        getDetail(id).then(res => {
            this.List = res;
        });
    },
    methods: {
        process(e) {
            e.preventDefault();
            if (!this.List.length) {
                this.$message.error("请填写危险有害因素");
                return;
            }
            this.loading = true;
            const { query } = this.$route;
            const { id } = query || {};
            assessed(this.List.map(v=>{
                return {
                    ...v,
                    pid: id
                }
            }))
            .then(() => {
                this.$close(this.$route.path);
            })
            .finally(() => {
                this.loading = false;
            });
        },
        handleSubmit(e) {
            e.preventDefault();
            if (!this.List.length) {
                this.$message.error("请填写危险有害因素");
                return;
            }
            this.loading = true;
            const { query } = this.$route;
            const { id } = query || {};
            save(this.List.map(v=>{
                return {
                    ...v,
                    pid: id
                }
            }))
            .then(() => {
                this.$close(this.$route.path);
            })
            .finally(() => {
                this.loading = false;
            });
        }
    },
};
</script>
  
  
<style lang="less" scoped>
.container {
    padding: 12px;
}

.center {
    margin-top: 80px;
    margin-bottom: 80px;
}
</style>